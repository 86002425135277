import { RestaurantBranchDto } from '@core/models';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

const initialState: RestaurantBranchDto = {
  id: '',
  restaurantId: '',
  name: '',
  about: '',
  address: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  phone: '',
  email: '',
  location: { type: 'Point', coordinates: [0, 0] },
  website: '',
  facebook: '',
  instagram: '',
  tikTok: '',
  logo: '',
  reviewCount: 0,
  rating: 0,
  categories: [],
  subCategories: [],
};

export const RestaurantStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store) => ({
    updateSelectedRestaurant(resDetails: RestaurantBranchDto) {
      patchState(store, resDetails);
    },
  }))
);
