import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { UserProfile, UserProfileDto } from '@core/models';
import { computed } from '@angular/core';
import { User } from 'firebase/auth';
import { DateTime } from 'luxon';

const initialState: UserProfile = {
  uid: '',
  userId: '',
  profileId: '',
  email: '',
  username: '',
  firstName: 'Res',
  lastName: 'Queue',
  dateOfBirth: null,
  phoneNumber: '--',
  avatar: '',
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ firstName, lastName }) => ({
    name: computed(() => `${firstName()} ${lastName()}`),
    initialsAvatar: computed(() => {
      if (firstName && lastName) {
        const firstNameInitial = `${firstName().charAt(0).toUpperCase()}`;
        const lastNameInitial = `${lastName().charAt(0).toUpperCase()}`;

        return `${firstNameInitial}${lastNameInitial}`;
      }

      return '';
    }),
  })),
  withMethods((store) => ({
    updateProfile(user: User, userProfile: UserProfileDto) {
      patchState(store, {
        uid: user.uid,
        userId: userProfile.userId,
        profileId: userProfile.profileId,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        dateOfBirth: userProfile.dateOfBirth
          ? (DateTime.fromISO(userProfile.dateOfBirth).toJSDate())
          : null,
        email: userProfile.email,
        username: userProfile.username,
        phoneNumber: userProfile.phoneNumber,
        avatar: userProfile.avatar,
      });
    },
  }))
);
