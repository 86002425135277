import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { UserLocation } from '@core/models/common';

const initialState: UserLocation = {
  country: null,
  state: null,
  city: null,
  latitude: null,
  longitude: null,
  locality: null,
};

export const UserLocationStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store) => ({
    updateLocation(newUserLocation: UserLocation) {
      patchState(store, {
        city: newUserLocation.city,
        state: newUserLocation.state,
        country: newUserLocation.country,
        latitude: newUserLocation.latitude,
        longitude: newUserLocation.longitude,
        locality: newUserLocation.locality,
      });
    },
  }))
);
